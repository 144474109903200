import { Alert, CircularProgress } from "@mui/material";
import {
  Body,
  Button,
  Cadence,
  CadencePicker,
  DayOfWeek,
  Input,
  InstallableContainer,
  NotificationBanner,
  Spacer,
  useToasts,
  useUpdateDetails,
} from "@vestaboard/installables";
import { Fixed, Toggle, UploadButton } from "../components";
import React, { useCallback, useEffect, useState } from "react";

import { IParams } from "../types";
import { ISubscriptionResult } from "../../../backend/types";
import { Scrollable } from "../components/Scrollable";
import { compose } from "lodash/fp";
import { makeStyles } from "@mui/styles";
import { useGetQuietHours } from "../hooks/useGetQuietHours";
import { useParams } from "../hooks";
import { useUpdateSubscription } from "../hooks/useUpdateSubscription";

interface ISettings {
  data: ISubscriptionResult | null;
  setData: (data: ISubscriptionResult | null) => void;
}

const useStyles = makeStyles({
  notification: {
    paddingTop: 24,
    textAlign: "center",
  },
});

export const Settings = (props: ISettings) => {
  const { updateTitle } = useUpdateDetails();
  const classes = useStyles();
  const params = useParams<IParams>();
  const { addToast } = useToasts();
  const [updateMutation, { loading, error, data }] = useUpdateSubscription();
  const [sortOrder, setSortOrder] = useState<string>(
    props.data?.sortOrder || "ordered"
  );
  const [title, setTitle] = useState(props?.data?.title || "");
  const [description, setDescription] = useState(
    props?.data?.description || ""
  );
  const [dirty, setDirty] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [icon, setIcon] = useState<string | undefined>(
    props?.data?.icon || undefined
  );
  const { data: quietHoursData } = useGetQuietHours({
    subscriptionConfigurationToken: params.subscriptionConfigurationToken,
  });
  const [cadence, setCadence] = useState<Cadence>({
    daysOfTheWeek: [
      DayOfWeek.Sunday,
      DayOfWeek.Monday,
      DayOfWeek.Tuesday,
      DayOfWeek.Wednesday,
      DayOfWeek.Thursday,
      DayOfWeek.Friday,
      DayOfWeek.Saturday,
    ],
    ...props.data?.cadence,
  } as Cadence);

  const { setData } = props;

  const formChange = useCallback(() => {
    setDirty(true);
  }, []);

  useEffect(() => {
    if (data) {
      setData(data);
      setValidationError(null);
    }
  }, [data, setData]);

  const alertValidationError = useCallback(() => {
    addToast(`There was an error saving your settings.`, {
      appearance: "error",
      autoDismiss: true,
    });
  }, [addToast]);

  return (
    <Scrollable>
      <InstallableContainer pad>
        {quietHoursData?.isQuietHours ? (
          <div className={classes.notification}>
            <NotificationBanner
              visible={quietHoursData?.isQuietHours || true}
              text={quietHoursData?.formattedQuietHours || ""}
            />
          </div>
        ) : null}
        <Spacer size="extraLarge" />
        <Body>Upload your own icon</Body>
        <Spacer size="small" />
        <UploadButton value={icon} setValue={compose(formChange, setIcon)} />
        <Spacer size="large" />
        <Body>Channel Name</Body>
        <Input
          value={title}
          onValueChange={compose(formChange, setTitle)}
          placeholder="My Channel"
          error={validationError === "title" ? "Required" : undefined}
        />
        <Spacer size="medium" />
        <Body>Description</Body>
        <Input
          value={description}
          onValueChange={compose(formChange, setDescription)}
          placeholder="Create a channel to repeat on an interval ranging from once a minute to once a year."
          multiline
          error={validationError === "description" ? "Required" : undefined}
        />
        <Spacer size="extraLarge" />
        <CadencePicker
          allowWeekdaySelection
          onChange={compose(formChange, setCadence)}
          cadence={cadence}
        />
        {validationError === "cadence" ? (
          <>
            <Alert severity="error">Cadence is required.</Alert>
            <Spacer size="large" />
          </>
        ) : null}
        <Spacer size="large" />
        <Body>Sort Order</Body>
        <Toggle
          options={[
            {
              value: "ordered",
              key: "In Order / Newest",
            },
            {
              value: "random",
              key: "Random",
            },
          ]}
          value={sortOrder}
          onToggle={compose(formChange, setSortOrder)}
        />
        {validationError === "sortOrder" ? (
          <>
            <Alert severity="error">Sort order is required.</Alert>
            <Spacer size="large" />
          </>
        ) : null}
        <Spacer size="extraLarge" />
        {error ? (
          <>
            <Alert severity="error">
              There was an error saving your settings.
            </Alert>
            <Spacer size="large" />
          </>
        ) : null}
        <Spacer size="extraLarge" />
      </InstallableContainer>
      {dirty ? (
        <Fixed height={105} orientation="bottom">
          <InstallableContainer pad>
            <div style={{ height: 30 }} />
            <Button
              buttonType="white"
              disabled={loading}
              onClick={async () => {
                if (!title || title === "My Channel") {
                  setValidationError("title");
                  alertValidationError();
                  return;
                }

                if (!description) {
                  setValidationError("description");
                  alertValidationError();
                  return;
                }

                if (!cadence?.interval) {
                  setValidationError("cadence");
                  alertValidationError();
                  return;
                }

                if (!sortOrder) {
                  setValidationError("sortOrder");
                  alertValidationError();
                  return;
                }

                setValidationError(null);

                updateTitle({
                  subscriptionId: params.subscriptionId,
                  title,
                  iconUrl: icon,
                });

                await updateMutation({
                  variables: {
                    id: params.subscriptionId,
                    cadence,
                    sortOrder,
                    title,
                    description,
                    icon,
                  },
                });

                addToast("Settings saved.", {
                  appearance: "success",
                  autoDismiss: true,
                });

                setDirty(false);
              }}
            >
              {loading ? <CircularProgress /> : "Save"}
            </Button>
          </InstallableContainer>
        </Fixed>
      ) : null}
    </Scrollable>
  );
};
